import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { ItemPaginationReport } from "../../../components/ItemPaginationReport";
// import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading";

interface TechVisitReport {
  id: string;
  costumer_id: string;
  user_id: string;
  code: string;
  begin: string;
  end: string;
  responsible_id: string;
  responsible_signature: string;
  technician_id: string;
  technician_signature: string;
  observation: string;
  pending: boolean;
  sent: boolean;
  costumer: Costumer;
  user: User;
  status: string;
  // technician: ;
  services: TechVisitReportService[];
  sectors: TechVisitReportSector[];
}

interface Costumer {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}

interface TechVisitReportService {
  id: string;
  name: string;
}

interface TechVisitReportSector {
  id: string;
  sector_id: string;
  observation: string;
  sector: Sector;
  equipments: TechVisitReportSectorEquipment[];
  images: TechVisitReportSectorImage[];
}

interface TechVisitReportSectorEquipment {
  id: string;
  equipment_id: string;
  grade: string;
  equipment: Equipment;
}

interface TechVisitReportSectorImage {
  id: string;
  file_name: string;
}

interface Equipment {
  id: string;
  name: string;
}

interface Sector {
  id: string;
  costumer_id: string;
  name: string;
}

interface Service {
  id: string;
  name: string;
}

interface ReportStatus {
  total: number;
  pending: number;
  concluded: number;
}

export function TechVisitReportList() {
  // const { user } = useContext(AuthContext);
  // const [techVisitReports, setTechVisitReports] = useState<TechVisitReport[]>(
  //   [],
  // );
  // const [costumers, setCostumers] = useState<Costumer[]>([]);
  // // const [services, setServices] = useState<Service[]>([]);
  // const [costumer_id, setCostumerId] = useState("");
  // const [pending, setPending] = useState("");
  // const [sent, setSent] = useState("");
  // const [statusFilter, setStatusFilter] = useState("A");
  // const [reportStatus, setReportStatus] = useState<ReportStatus>({
  //   total: 0,
  //   pending: 0,
  //   concluded: 0,
  // });

  // const [pagination, setPagination] = useState(false);

  // const [beginFilter, setBeginFilter] = useState(
  //   format(new Date(), "yyyy-MM-dd 00:00:00"),
  // );
  // const [endFilter, setEndFilter] = useState(
  //   format(new Date(), "yyyy-MM-dd 23:59:59"),
  // );

  // const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   api
  //     .get("/costumers")
  //     .then(response => {
  //       return setCostumers(response.data);
  //     })
  //     .catch(() => {
  //       navigate("/signin");
  //     });

  //   // api.get("/services").then(response => {
  //   //   return setServices(response.data);
  //   // });
  // }, []);

  // function paginarTechVisitReports() {
  //   if (loading) return;

  //   setLoading(true);
  //   api
  //     .get("/techVisitReport", {
  //       params: {
  //         costumer_id,
  //         pending,
  //         sent,
  //         status: statusFilter,
  //         begin: beginFilter,
  //         end: endFilter,
  //         page: 1,
  //         limit: 10,
  //       },
  //     })
  //     .then(response => {
  //       const result = response.data.data;
  //       setTechVisitReports(result);
  //       setHasMore(result.length > 0);

  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // }

  // function paginarTechVisitReportsMore(actualPage: number) {
  //   if (loading) return;

  //   setLoading(true);
  //   api
  //     .get("/techVisitReport", {
  //       params: {
  //         costumer_id,
  //         pending,
  //         sent,
  //         status: statusFilter,
  //         begin: beginFilter,
  //         end: endFilter,
  //         page: actualPage,
  //         limit: 10,
  //       },
  //     })
  //     .then(response => {
  //       const result = response.data.data;
  //       setTechVisitReports(prev => [...prev, ...result]);
  //       setHasMore(result.length > 0);

  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // }

  // // Quando troca o filtro ele volta para página 1
  // useEffect(() => {
  //   paginarTechVisitReports();
  // }, [costumer_id, pending, sent, statusFilter, beginFilter, endFilter]);

  // // Quando faz scroll ele mantém o filtro e adiciona a página
  // useEffect(() => {
  //   if (loading === false) {
  //     paginarTechVisitReportsMore(page);
  //   }
  // }, [pagination, page]);

  // useEffect(() => {
  //   const pendingCount = techVisitReports.filter(
  //     element => element.pending === false,
  //   );
  //   const concludedCount = techVisitReports.filter(
  //     element => element.pending === true,
  //   );

  //   setReportStatus({
  //     total: techVisitReports.length,
  //     pending: pendingCount.length,
  //     concluded: concludedCount.length,
  //   });
  // }, [techVisitReports]);

  // const handleScroll = () => {
  //   if (loading || !hasMore) return;

  //   // Verifica a posição do scroll global da página
  //   const scrollPosition = window.scrollY + window.innerHeight;
  //   const bottomPosition = document.documentElement.scrollHeight;

  //   // Se o scroll estiver perto do final da página, carregue mais dados
  //   if (scrollPosition >= bottomPosition - 100) {
  //     setPage(prev => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   // Adiciona o evento de scroll global
  //   window.addEventListener("scroll", handleScroll);

  //   // Limpeza do evento quando o componente é desmontado
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [loading, hasMore, page]);

  // useEffect(() => {
  //   const container = document.getElementById("item-container");
  //   if (container) {
  //     container.addEventListener("scroll", handleScroll);
  //     return () => container.removeEventListener("scroll", handleScroll);
  //   }
  //   return undefined;
  // }, [hasMore, loading]);

  // Ajuste no gerenciamento de estado e paginação infinita
  const [techVisitReports, setTechVisitReports] = useState<TechVisitReport[]>(
    [],
  );
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [costumer_id, setCostumerId] = useState("");
  const [pending, setPending] = useState("");
  const [sent, setSent] = useState("");
  const [statusFilter, setStatusFilter] = useState("A");
  const [reportStatus, setReportStatus] = useState<ReportStatus>({
    total: 0,
    pending: 0,
    concluded: 0,
  });
  const [pagination, setPagination] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [beginFilter, setBeginFilter] = useState(
    format(new Date(), "yyyy-MM-dd 00:00:00"),
  );
  const [endFilter, setEndFilter] = useState(
    format(new Date(), "yyyy-MM-dd 23:59:59"),
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  console.log(page);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  // Função para carregar a primeira página ou reiniciar os filtros
  function paginarTechVisitReports() {
    if (loading) return;

    setLoading(true);
    setPage(1); // Reinicia a página ao carregar novos filtros
    api
      .get("/techVisitReport", {
        params: {
          costumer_id,
          pending,
          sent,
          status: statusFilter,
          begin: beginFilter,
          end: endFilter,
          page: 1,
          limit: 10,
        },
      })
      .then(response => {
        const result = response.data.data;
        setTechVisitReports(result);
        setHasMore(result.length > 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  // Função para carregar mais páginas ao fazer scroll
  function paginarTechVisitReportsMore() {
    if (loading || !hasMore) return;

    setLoading(true);
    api
      .get("/techVisitReport", {
        params: {
          costumer_id,
          pending,
          sent,
          status: statusFilter,
          begin: beginFilter,
          end: endFilter,
          page,
          limit: 10,
        },
      })
      .then(response => {
        const result = response.data.data;
        setTechVisitReports(prev => [...prev, ...result]);
        setHasMore(result.length > 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  // Carregar dados ao alterar filtros
  useEffect(() => {
    paginarTechVisitReports();
  }, [costumer_id, pending, sent, statusFilter, beginFilter, endFilter]);

  // Carregar mais dados ao alterar a página
  useEffect(() => {
    if (page > 1) {
      paginarTechVisitReportsMore();
    }
  }, [page]);

  // Atualizar contagem de relatórios
  useEffect(() => {
    const pendingCount = techVisitReports.filter(
      element => element.pending === false,
    ).length;
    const concludedCount = techVisitReports.filter(
      element => element.pending === true,
    ).length;

    setReportStatus({
      total: techVisitReports.length,
      pending: pendingCount,
      concluded: concludedCount,
    });
  }, [techVisitReports]);

  // Função de scroll para carregar mais dados
  const handleScroll = () => {
    if (loading || !hasMore) return;

    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.documentElement.scrollHeight;

    if (scrollPosition >= bottomPosition - 100) {
      setPage(page + 1);
    }
  };

  // Adicionar e remover evento de scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  // Carregar lista de clientes ao montar o componente
  useEffect(() => {
    api
      .get("/costumers")
      .then(response => {
        setCostumers(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="pb-6">
        <div className="w-full min-h-[56px] flex items-center p-4 mt-2 bg-[#0085BD] rounded-lg">
          <span className="text-white text-md font-medium">
            {reportStatus.total}
          </span>
          <span className="text-white text-md font-medium ml-6">
            Relatórios
          </span>
        </div>
        <div className="w-full min-h-[56px] flex items-center p-4 mt-2 bg-[#92C163] rounded-lg">
          <span className="text-white text-md font-medium">
            {reportStatus.pending}
          </span>
          <span className="text-white text-md font-medium ml-6">
            Concluídos
          </span>
        </div>
        <div className="w-full min-h-[56px] flex items-center p-4 mt-2 bg-danger-500 rounded-lg">
          <span className="text-white text-md font-medium">
            {reportStatus.concluded}
          </span>
          <span className="text-white text-md font-medium ml-6">Pendentes</span>
        </div>
        <div className="flex flex-col mt-1">
          <span className="text-small text-white">Clientes</span>
          <select
            name="costumer_id"
            onChange={e => setCostumerId(e.target.value)}
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultValue="">
              Todos
            </option>
            {costumers &&
              costumers.map(({ id: costumerId, name }: Costumer) => (
                <option key={costumerId} value={costumerId}>
                  {name}
                </option>
              ))}
          </select>
          <span className="text-small text-white">Status conclusão</span>
          <select
            name="costumer_id"
            onChange={e => setPending(e.target.value)}
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultValue="">
              Todos
            </option>
            <option value="true">Pendente</option>
            <option value="false">Concluído</option>
          </select>
          <span className="text-small text-white">Status envio</span>
          <select
            onChange={e => setSent(e.target.value)}
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultValue="">
              Todos
            </option>
            <option value="true">Enviado</option>
            <option value="false">Não enviado</option>
          </select>
          <span className="text-small text-white">Status relatório</span>
          <select
            onChange={e => setStatusFilter(e.target.value)}
            defaultValue="A"
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultValue="">
              Todos
            </option>
            <option value="A">Ativo</option>
            <option value="I">Inativo</option>
          </select>
          <span className="text-small text-white">Data</span>
          <DatePicker
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            isClearable
            onChange={update => {
              setDateRange(update);
              const begin = moment(update[0]).format("YYYY-MM-DDT00:00:00");
              const end = moment(update[1]).format("YYYY-MM-DDT23:59:59");

              if (update[0] === null && update[1] === null) {
                setBeginFilter(null);
                setEndFilter(null);
              }

              if (begin <= end && update[1] !== null) {
                setBeginFilter(begin);
                setEndFilter(end);
              }
            }}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
          />
        </div>

        {techVisitReports.length > 0 ? (
          techVisitReports.map(
            ({
              id,
              costumer,
              begin,
              sent: sented,
              pending: pended,
              status,
              services,
            }) => (
              <ItemPaginationReport
                key={id}
                id={id}
                title={costumer.name}
                begin={begin}
                pending={pended}
                sent={sented}
                status={status}
                services={services}
                costumer_id={costumer.id}
                setPagination={() => setPagination(!pagination)}
              />
            ),
          )
        ) : (
          <div className="w-full mt-6 p-5 rounded-lg bg-[#0085BD]">
            <span className="text-sm text-medium text-white">
              Nenhum registro foi encontrado 👀
            </span>
          </div>
        )}
      </div>
    </>
  );
}
